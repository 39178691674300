export const content = {
  books: {
    en: "Books",
    hi: "किताबें",
  },
  percentageOff: {
    en: "Off",
    hi: "छूट",
  },
  ratings: {
    en: "Rating",
    hi: "रैटिंग",
  },
  and: {
    en: "&",
    hi: "और",
  },
  reviews: {
    en: "Reviews",
    hi: " रिव्यु",
  },
  tagline: {
    en: "Hurry up! Sale Price Ends Tonight",
    hi: "जल्दी करें! सेल बस आज तक सीमित है",
  },
  hrs: {
    en: "Hrs",
    hi: "घंटे",
  },
  mins: {
    en: "Mins",
    hi: "मिनट",
  },
  secs: {
    en: "Secs",
    hi: "सेकंड",
  },
  highlights: {
    en: "What's Inside?",
    hi: "पैकेज में पाएं",
  },
  testimonialHeading: {
    en: "Success Stories",
    hi: "सफलता के कदम",
  },
  descriptionHeading: {
    en: "Description",
    hi: "विवरण",
  },
  sampleHeading: {
    en: "Samples",
    hi: "सैंपल",
  },
  reviewHeading: {
    en: "Reviews",
    hi: "रेटिंग",
  },
  verifiedCustomers: {
    en: "Verified Student",
    hi: "सत्यापित छात्र",
  },
  seeReviews: {
    en: "See All Reviews",
    hi: "सभी रिव्यु देखें",
  },
  cta: {
    en: "Buy Now",
    hi: "Buy Now",
  },
};
