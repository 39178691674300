import React from "react";
import styles from "./product.module.scss";

const DoubleHeading = ({ text }) => {
  return (
    <div className="relative sm:pt-12 pt:0 bg-white">
      <center>
        {text && (
          <h2 className="font-extrabold text-2xl sm:text-4xl text-uppercase">
            {text.length < 21 ? text : text.substring(0, 21) + "..."}
          </h2>
        )}
        <div
          className={`${styles.clone} font-bold text-3xl sm:text-6xl text-uppercase left-0`}
        >
          {text}
        </div>
      </center>
    </div>
  );
};

export default DoubleHeading;
